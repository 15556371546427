import React from "react"
import { Media } from "../Media"
// import Layout from "../components/Layout"
import SEO from "../components/seo"

export default function Home({ children }) {
  return <div className="accordion__content">
    <SEO/>
    <h2 className='artist__name'>Künstler*in</h2>
    <Media at="sm">
    <section><p>Wählen Sie eine(n) Künstler*in aus dem unteren Kartenreiter aus.</p></section>
    </Media>
    <Media greaterThan="sm">
    <section><p>Wählen Sie eine(n) Künstler*in aus dem rechten Kartenreiter aus.</p></section>
    </Media>
    </div>
}
